import './styles.css'
import { Rocket, Earth, Trophy, Handshake } from 'lucide-react'
import React, { useState, useEffect } from 'react'

export default function Overlay() {
  const [isScrolled, setIsScrolled] = useState(false)

  // Detect scroll event and add blur effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Adjust scroll threshold as needed
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className="container">
      <header className={isScrolled ? 'blur' : 'no-blur'}>
        <div className="brand">
          <img src="brand.png" width={30} />
          <p>
            <strong>USDX</strong>
          </p>
        </div>
        {/* <div>
          <ul>
            <li>Learn</li>
            <li>Numbers</li>
            <li>Engage</li>
          </ul>
        </div> */}
        <button onClick={() => (window.location.href = 'https://dapp.trademarketcap.ai/')}>
          Connect Wallet
        </button>
      </header>

      <div className="main-wrapper">
        <section className="section section-1">
          <div className="wrapper">
            <h2>
              <strong>USDX</strong>
            </h2>
            <h2>Stability Perfected, Freedom Unleashed</h2>
            <p>
              Experience the future of decentralized finance with <strong>USDX</strong>, the first
              truly world's first community-driven decentralized stablecoin pegged
              <strong> 1:1 to DAI. </strong>Governed by cutting-edge technology and driven by{' '}
              <strong>
                community-centric principles, USDX delivers unparalleled price stability{' '}
              </strong>
              combined with the <strong>speed and efficiency of the Polygon Blockchain. </strong>
              Welcome to the ultimate digital asset for the
              <strong> decentralized era.</strong>
            </p>
          </div>
        </section>

        <section className="section section-2">
          <div className="wrapper">
            <h2>
              Why <strong>USDX?</strong>
            </h2>

            <p>
              <strong>USDX </strong>redefines <strong>stability and security </strong>in
              decentralized finance. Powered by the <strong>Polygon Blockchain, </strong>it offers:
            </p>
            <ul>
              <li>
                <strong>Fast, Low-Cost Transactions: </strong>Lightning-speed transfers with minimal
                fees.
              </li>
              <li>
                <strong>Stable $1 Peg: </strong>Consistent value users can trust.
              </li>
              <li>
                <strong>Trigger Peg Bot: </strong>Automated stability with zero human intervention.
              </li>
              <li>
                <strong>Decentralized Governance: </strong>Community-driven, transparent, and
                tamper-proof.
              </li>
            </ul>
            <p>
              <strong>
                USDX isn’t just a stablecoin; it’s the new gold standard for financial freedom.
              </strong>
            </p>
          </div>
        </section>

        <section className="section section-3 ">
          <div className="wrapper">
            {/* <div className="card-wrapper">
            <div className="card">
              <h2>85%</h2>
              <p>
                Over 85% of participants experienced significant improvements in memory and
                cognitive function within the first 6 weeks
              </p>
            </div>
            <div className="card">
              <h2>85%</h2>
              <p>
                Over 85% of participants experienced significant improvements in memory and
                cognitive function within the first 6 weeks
              </p>
            </div>
          </div> */}
            <div className="numbers-title">
              <h2>
                <strong>Key Highlights</strong>
              </h2>
              <p>
                <strong>Trigger Peg Technology – Unwavering Stability, Fully Automated</strong>
              </p>
              <p>
                The core of <strong>USDX’s </strong>stability lies in its groundbreaking{' '}
                <strong>Trigger Peg Bot Technology. </strong>This revolutionary algorithm ensures
                precise price alignment without requiring any human intervention:
              </p>
              <ul>
                <li>
                  <strong>Smart Market Monitoring: </strong>Constantly tracks <strong>USDX </strong>
                  prices across various markets, instantly identifying and addressing any price
                  deviations.
                </li>
                <li>
                  <strong>Automated Price Adjustments: </strong>Every 6 hours and 30 minutes, the
                  bot recalibrates USDX to maintain its perfect alignment with $1 DAI.
                </li>
                <li>
                  <strong>Fully Autonomous: </strong>The system operates seamlessly and without
                  human oversight, eliminating errors and ensuring continuous stability for USDX
                  holders.
                </li>
              </ul>
              <p>
                With this advanced technology, <strong>USDX </strong>offers unmatched
                <strong> reliability </strong>and <strong>stability 24/7.</strong>
              </p>
              <p>
                With the
                <strong>Trigger Peg Bot, USDX</strong>
                sets a new benchmark for
                <strong>decentralized price stability,</strong>giving users peace of mind and
                <strong>consistent value in every transaction.</strong>
              </p>
            </div>
          </div>
        </section>
        <section className="section-4">
          <section className="section section-2">
            <div className="wrapper">
              <h2>
                A Decentralized <strong>Liquidity Pool (LP)</strong> Mechanism
              </h2>
              <p>
                <strong>USDX </strong>introduces an innovative <strong>USDX LP </strong>system
                designed to prioritize
                <strong>transparency, security, </strong>and <strong>long-term stability: </strong>
              </p>
              <ul>
                <li>
                  <strong>Automated Liquidity Provision:</strong>
                  <div className="card-wrapper">
                    <div className="card">
                      <h2>10%</h2>
                      <p>
                        <strong>10% </strong>of the staking protocol’s fees are allocated to the
                        liquidity pool, ensuring consistent liquidity for decentralized exchanges.
                      </p>
                    </div>
                    <div className="card">
                      <h2>50%</h2>
                      <p>
                        Funds are evenly split between <strong>50% USDX </strong>and{' '}
                        <strong>50% DAI, </strong>
                        creating a balanced and resilient liquidity structure.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              <ul>
                <li>
                  <strong>LP Token Burn: </strong>Minted LP tokens are sent to the
                  <strong> LP Treasury, </strong>where they are permanently locked. This mechanism
                  ensures perpetual liquidity and reinforces price stability over time.
                </li>
              </ul>
              <p>
                This advanced
                <strong> LP</strong> system fosters trust through its
                <strong>transparent, tamper-proof design,</strong>securing
                <strong>USDX’s liquidity and value.</strong>
              </p>
            </div>
          </section>
        </section>
        <section className="section-5">
          <section className="section section-3">
            <div className="wrapper">
              {/* <div className="card-wrapper">
            <div className="card">
              <h2>85%</h2>
              <p>
                Over 85% of participants experienced significant improvements in memory and
                cognitive function within the first 6 weeks
              </p>
            </div>
            <div className="card">
              <h2>85%</h2>
              <p>
                Over 85% of participants experienced significant improvements in memory and
                cognitive function within the first 6 weeks
              </p>
            </div>
          </div> */}
              <div className="numbers-title">
                <h2>
                  Unleashing the Power of <strong>USDX Live Trade </strong> and{' '}
                  <strong>Treasury Management</strong>
                </h2>
                <p>
                  <strong></strong>
                  <strong></strong>
                </p>
                <ol>
                  <li>
                    <strong>Cutting-Edge Smart Contracts Driving USDX Stability</strong>
                    <br />
                    The USDX ecosystem relies on sophisticated live trading smart contracts to
                    deliver consistent, sustainable rewards. By seamlessly integrating with the
                    treasury, these contracts execute high-frequency trades, continuously amplifying
                    the value of USDX and maintaining a dynamic, growing rewards pool. This ensures
                    both stability and profitability for USDX holders, reinforcing its place at the
                    forefront of decentralized finance.
                  </li>
                  <br />
                  <li>
                    <strong>Altcoin Growth Engine</strong>
                    <br />
                    The Treasury Contract employs a strategic trading approach, diversifying its
                    holdings across a carefully curated selection of altcoins. Through expert
                    management, these assets are optimized to unlock maximum value, creating a solid
                    financial foundation that supports the long-term growth and stability of the
                    USDX ecosystem.
                  </li>
                  <br />
                  <li>
                    <strong>AI-Powered Scalping Excellence</strong>
                    <br />
                    At the core of USDX’s treasury growth is the <strong>AI Scalping Bot, </strong>a
                    cutting-edge algorithm designed to swiftly identify and capitalize on market
                    fluctuations. Running 24/7, this bot generates consistent daily profits,
                    strengthening the treasury and ensuring the sustained stability and growth of
                    the USDX ecosystem.
                  </li>
                </ol>
              </div>
            </div>
          </section>
        </section>
        <section className="section-6">
          <section className="section section-2">
            <div className="wrapper">
              {/* <div className="card-wrapper">
            <div className="card">
              <h2>85%</h2>
              <p>
                Over 85% of participants experienced significant improvements in memory and
                cognitive function within the first 6 weeks
              </p>
            </div>
            <div className="card">
              <h2>85%</h2>
              <p>
                Over 85% of participants experienced significant improvements in memory and
                cognitive function within the first 6 weeks
              </p>
            </div>
          </div> */}
              <div className="numbers-title">
                <h2>
                  Decentralized to the Core –<strong>No Ownership, No Manipulation</strong>
                </h2>
                <p>
                  USDX goes beyond traditional stablecoins by being fully{' '}
                  <strong>decentralized </strong>and <strong>community-driven. </strong>Unlike
                  centralized stablecoins, <strong>USDX </strong>is immune to ownership or
                  manipulation, offering complete transparency, autonomy, and freedom to its users.
                  This ensures a fair, trustless environment that aligns with the true principles of
                  decentralization.
                </p>
              </div>
            </div>
          </section>
        </section>
        <section className="section-7">
          <section className="section section-3">
            <div className="wrapper">
              <div className="numbers-title">
                <h2>
                  Breaking Free from <strong>Centralized Stablecoins</strong>
                </h2>
                <p>
                  Traditional stablecoins like <strong>DAI </strong>and <strong>USDC </strong>come
                  with significant centralized risks:
                </p>
                <ul>
                  <li>
                    <strong>Centralized Custody: </strong>Reserves are controlled by a single
                    entity, opening the door to freezing, censorship, or seizure.
                  </li>
                  <li>
                    <strong>Lack of Transparency: </strong>Users must trust centralized
                    organizations to manage reserves, often with limited visibility and auditing.
                  </li>
                  <li>
                    <strong>Regulatory Risks: </strong>Centralized stablecoins face heightened
                    regulatory scrutiny and compliance challenges.
                  </li>
                </ul>
                <p>
                  <strong>USDX </strong>eliminates these vulnerabilities by offering a{' '}
                  <strong>fully decentralized, trustless solution. </strong>Break free from the
                  limitations of centralized stablecoins and embrace the future of financial freedom
                  with USDX.
                </p>
              </div>
            </div>
          </section>
        </section>
        <section className="section-8">
          <section className="section section-2">
            <div className="wrapper">
              <div className="numbers-title">
                <h2>
                  Powered by <strong>Polygon </strong>Blockchain –
                  <strong>Fast, Scalable & Cost-Effective</strong>
                </h2>
                <p>
                  <strong>USDX</strong>USDX harnesses the power of the
                  <strong> Polygon Blockchain </strong>to deliver an exceptional user experience:
                </p>
                <ul>
                  <li>
                    <strong>Minimal Transaction Fees: </strong>Enjoy seamless transfers and staking
                    without breaking the bank.
                  </li>
                  <li>
                    <strong>Blazing-Fast Speeds: </strong>Near-instant transactions ensure quick and
                    efficient operations.
                  </li>
                  <li>
                    <strong>Scalable and Reliable: </strong>Built for high-volume activity, the
                    infrastructure ensures smooth performance, even during peak usage.
                  </li>
                </ul>
                <p>
                  The <strong>Polygon Blockchain </strong>
                  empowers <strong>USDX </strong>
                  with unmatched
                  <strong> efficiency, accessibility, and scalability, </strong>
                  making it a top choice in the decentralized finance ecosystem.
                </p>
              </div>
            </div>
          </section>
        </section>
        <section className="section-9">
          <section className="section section-3">
            <div className="wrapper">
              <div className="numbers-title">
                <h2>
                  Your Path to Rewards with <strong>USDX</strong>
                </h2>
                <ul>
                  <li>
                    <strong>Seamless Staking</strong>
                    <br />
                    Maximize the potential of your assets with USDX Staking, offering flexible and
                    rewarding opportunities.
                  </li>
                  <ul>
                    <li>
                      <strong>Earn Daily Returns: </strong>Enjoy returns ranging from 0.3% to 1%,
                      tailored to your staking tier.
                    </li>
                    <li>
                      <strong>Flexible Options: </strong>Whether you're a beginner or an experienced
                      investor, our platform enables you to enhance your gains through secure,
                      transparent, and efficient staking solutions.
                    </li>
                  </ul>
                  {/* <div className="card-wrapper">
                  <div className="card">
                    <h2>0.3%</h2>
                    <p>
                      <strong>Regular Staking: </strong>Earn 0.3% daily Yield with stable,
                      predictable returns.
                    </p>
                  </div>
                  <div className="card">
                    <h2>0.4%</h2>
                    <p>
                      <strong>Smart X Staking: </strong>Enjoy higher rewards with 0.4% daily Yield,
                      maximizing your earning potential.
                    </p>
                  </div>
                  <div className="card">
                    <h2>0.5%</h2>
                    <p>
                      <strong>Plus X Staking: </strong>Enjoy higher rewards with 0.5% daily Yield,
                      maximizing your earning potential.
                    </p>
                  </div>
                  <div className="card">
                    <h2>0.6%</h2>
                    <p>
                      <strong>Pro X Staking: </strong>Enjoy higher rewards with 0.6% daily Yield,
                      maximizing your earning potential.
                    </p>
                  </div>
                </div>
                <br></br>
                <div className="card-wrapper">
                  <div className="card">
                    <h2>0.7%</h2>
                    <p>
                      <strong>Royal X Staking: </strong>Earn 0.7% daily Yield with stable,
                      predictable returns.
                    </p>
                  </div>
                  <div className="card">
                    <h2>0.8%</h2>
                    <p>
                      <strong>Chief X Staking: </strong>Enjoy higher rewards with 0.8% daily Yield,
                      maximizing your earning potential.
                    </p>
                  </div>
                  <div className="card">
                    <h2>1%</h2>
                    <p>
                      <strong>Empire X Staking: </strong>Enjoy higher rewards with 1% daily Yield,
                      maximizing your earning potential.
                    </p>
                  </div>
                </div> */}
                </ul>
                <ul>
                  <li>
                    <strong>Affiliate Income: Expand Your Earnings</strong>
                    <br />
                    USDX provides a professional affiliate income system, allowing users to grow
                    their network and earn through community-driven contributions.
                  </li>
                  <ul>
                    <li>
                      <strong>Performance-Based Rewards: </strong>Unlock earning potential through
                      an innovative, transparent model that aligns rewards with your engagement and
                      performance.
                    </li>
                  </ul>
                </ul>
                <ul>
                  <li>
                    <strong>Advanced Rank System</strong>
                    <br />
                    Climb the ranks and unlock exclusive rewards:
                  </li>
                  <ul>
                    <li>
                      <strong>One-Time Rewards: </strong>Receive lucrative incentives for achieving
                      business milestones.
                    </li>
                    <li>
                      <strong>Monthly Incentives: </strong>Enjoy regular rewards for consistent
                      contributions.
                    </li>
                    <li>
                      <strong>Global Turnover Share: </strong>Top achievers can earn from USDX’s
                      global success, benefiting from a share in the platform's growth.
                    </li>
                  </ul>
                </ul>
                <p>
                  <strong>USDX </strong>empowers you to turn your contributions into substantial
                  rewards, offering a pathway to financial success and growth.
                </p>
              </div>
            </div>
          </section>
        </section>
        <section className="section-10">
          <section className="section section-2">
            <div className="wrapper">
              <div className="numbers-title">
                <h2>
                  Why Choose <strong>USDX Staking?</strong>
                </h2>
                <ul>
                  <li>
                    <strong>Attractive Returns: </strong>
                    Earn daily rewards that increase with your continued commitment, offering a
                    dynamic return on investment.
                  </li>
                  <br />
                  <li>
                    <strong>Effortless Experience: </strong>
                    Easy-to-use platform for quick onboarding and hassle-free stake management.
                  </li>
                  <br />
                  <li>
                    <strong>Unmatched Security: </strong>
                    Built on blockchain technology, ensuring complete safety, transparency, and
                    peace of mind.
                  </li>
                </ul>
                <p>
                  <strong>USDX Staking</strong>combines simplicity, security, and rewarding growth,
                  making it the ideal choice for your financial journey.
                </p>
              </div>
            </div>
          </section>
        </section>
        <section className="section-11">
          <section className="section section-3">
            <div className="wrapper">
              <div className="numbers-title">
                <h2>
                  Why USDX is the
                  <strong> Ultimate Financial Revolution?</strong>
                </h2>
                <p>
                  <strong>USDX </strong>is setting a new standard in decentralized finance by
                  combining innovative technology, robust rewards, and dynamic market strategies.
                  With live trade mechanisms and treasury-backed growth, USDX delivers unparalleled
                  stability and positions itself as a leader in the DeFi space.
                </p>
                <h2>Join the Movement – </h2>
                <p>
                  Step into a future where financial freedom meets cutting-edge technology and
                  community-driven values.{' '}
                  <strong>USDX: Stability Redefined, Freedom Empowered.</strong>
                </p>
              </div>
            </div>
          </section>
        </section>
        {/* <section className="section-11">
          <section className="section section-3">
            <div className="wrapper">
              <div className="numbers-title">
                <h2>
                  Unleashing Marketing Power – <strong>The USDX Roadmap</strong>
                </h2>
                <div className="max-w-2xl mx-auto p-4 space-y-16">
                  <div className="relative">
                    <div className="absolute left-8 top-8 bottom-0 w-0.5 bg-gray-200 border-dashed border-l-2 border-gray-200" />
                    <div className="relative flex items-start gap-4 pb-12">
                      <div className="flex-1 pt-2">
                        <div className="flex items-center gap-2 mb-1">
                          <h2 className="text-4xl font-bold text-gray-300">Q1</h2>
                          <div className="relative z-10">
                            <div
                              className={`bg-red-400 rounded-full w-20 h-20 flex items-center justify-center shadow-lg`}>
                              <Rocket className="w-60 h-60 text-white" />
                            </div>
                          </div>
                          <h1 className="text-xl font-semibold">Immediate Launch</h1>
                        </div>
                        <ul className="text-gray-600 list-disc pl-5 max-w-md ml-6">
                          <li className="mb-2">
                            <strong>Full Functionality Available Day One: </strong>All smart
                            contract features, including staking, LP provisioning, Trigger Peg Bot,
                            and referral systems, are live from the start.
                          </li>
                          <li className="mb-2">
                            <strong>Global Awareness Campaign: </strong>Launch across major
                            platforms with a focus on DeFi communities, early adopters, and KOLs
                            (Key Opinion Leaders).
                          </li>
                          <li className="mb-2">
                            <strong>Airdrop & Referral Drives: </strong>Distribute USDX to
                            incentivize new users and generate organic word-of-mouth buzz.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="max-w-2xl mx-auto p-4 space-y-16">
                  <div className="relative">
                    <div className="absolute left-8 top-8 bottom-0 w-0.5 bg-gray-200 border-dashed border-l-2 border-gray-200" />
                    <div className="relative flex items-start gap-4 pb-12">
                      <div className="flex-1 pt-2">
                        <div className="flex items-center gap-2 mb-1">
                          <h2 className="text-4xl font-bold text-gray-300">Q2</h2>
                          <div className="relative z-10">
                            <div
                              className={`bg-red-400 rounded-full w-20 h-20 flex items-center justify-center shadow-lg`}>
                              <Handshake className="w-12 h-12 text-white" />
                            </div>
                          </div>
                          <h3 className="text-xl font-semibold">
                            Strategic Partnerships & Mass Adoption
                          </h3>
                        </div>
                        <ul className="text-gray-600 list-disc pl-5 max-w-md ml-6">
                          <li className="mb-2">
                            <strong>Partnerships with Big Brands & Projects: </strong>Collaborate
                            with leading DeFi platforms, wallets, and blockchain ecosystems to
                            integrate USDX.
                          </li>
                          <li className="mb-2">
                            <strong>Engage KOLs and Influencers: </strong>Partner with prominent
                            crypto influencers and industry leaders to amplify visibility.
                          </li>
                          <li className="mb-2">
                            <strong>Major Media Coverage: </strong>Secure feature articles in
                            top-tier media outlets like Forbes, CoinDesk, CryptoSlate, and more to
                            establish USDX as a market leader.
                          </li>
                          <li className="mb-2">
                            <strong>Exchange Listings: </strong>Start negotiations with major
                            decentralized and centralized exchanges for USDX listing.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="max-w-2xl mx-auto p-4 space-y-16">
                  <div className="relative">
                    <div className="absolute left-8 top-8 bottom-0 w-0.5 bg-gray-200 border-dashed border-l-2 border-gray-200" />
                    <div className="relative flex items-start gap-4 pb-12">
                      <div className="flex-1 pt-2">
                        <div className="flex items-center gap-2 mb-1">
                          <h2 className="text-4xl font-bold text-gray-300">Q3</h2>
                          <div className="relative z-10">
                            <div
                              className={`bg-red-400 rounded-full w-20 h-20 flex items-center justify-center shadow-lg`}>
                              <Earth className="w-12 h-12 text-white" />
                            </div>
                          </div>
                          <h3 className="text-xl font-semibold">Global Expansion & Dominance</h3>
                        </div>
                        <ul className="text-gray-600 list-disc pl-5 max-w-md ml-6">
                          <li className="mb-2">
                            <strong>Worldwide Ad Campaigns: </strong>Leverage digital marketing with
                            high-impact campaigns on Google, YouTube, and Twitter.
                          </li>
                          <li className="mb-2">
                            <strong>Local Outreach: </strong>Host in-person and virtual events
                            targeting emerging markets in Asia, Africa, and Latin America.
                          </li>
                          <li className="mb-2">
                            <strong>Ambassador Program: </strong>Recruit and empower community
                            leaders to represent USDX globally, creating localized strategies for
                            adoption.
                          </li>
                          <li className="mb-2">
                            <strong>Big-Brand Sponsorships: </strong>Secure sponsorships with
                            recognized brands in finance, gaming, and e-commerce to position USDX as
                            a mainstream asset.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="max-w-2xl mx-auto p-4 space-y-16">
                  <div className="relative">
                    <div className="absolute left-8 top-8 bottom-0 w-0.5 bg-gray-200 border-dashed border-l-2 border-gray-200" />
                    <div className="relative flex items-start gap-4 pb-12">
                      <div className="flex-1 pt-2">
                        <div className="flex items-center gap-2 mb-1">
                          <h2 className="text-4xl font-bold text-gray-300">Q4</h2>
                          <div className="relative z-10">
                            <div
                              className={`bg-red-400 rounded-full w-20 h-20 flex items-center justify-center shadow-lg`}>
                              <Trophy className="w-12 h-12 text-white" />
                            </div>
                          </div>
                          <h3 className="text-xl font-semibold">
                            Recognition & Institutional Integration
                          </h3>
                        </div>
                        <ul className="text-gray-600 list-disc pl-5 max-w-md ml-6">
                          <li className="mb-2">
                            <strong>Big Media Push: </strong>Feature USDX in documentaries,
                            podcasts, and case studies showcasing its impact on decentralized
                            finance.
                          </li>
                          <li className="mb-2">
                            <strong>Institutional Partnerships: </strong>Build relationships with
                            venture capital firms, asset managers, and institutional players to
                            integrate USDX into large-scale DeFi operations.
                          </li>
                          <li className="mb-2">
                            <strong>Major Exchange Listings: </strong>Secure listings on top
                            exchanges like Binance, Coinbase, and Bybit to cement USDX’s position as
                            a global stablecoin leader.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section> */}
      </div>

      <footer>
        <div className="footer-left">
          <h3>USDX</h3>
          {/* <p>Stability Perfected, Freedom Unleashed</p> */}
          <div className="social">
            <img src="instagram.svg" width={20} />
            <img src="youtube.svg" width={20} />
            <img src="x.svg" width={20} />
            <img src="telegram.svg" width={20} />
          </div>
          <p className="copyright">Copyright @usdx.live All rights reserved.</p>
        </div>
        <div className="footer-right">
          <img src="brand.png" width={40} />

          {/* <ul>
            <li>Privacy policy</li>
            <li>Terms of service</li>
            <li>Cookie policy</li>
            <li>Disclaimer</li>
          </ul> */}
        </div>
      </footer>
    </div>
  )
}
